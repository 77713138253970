import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';
import { commonColorHandler } from 'utils/colorsHandler';

import { StatisticsWithImagesProps } from './models';
import StatisticsWithImagesBlocks from './StatisticsWithImagesBlocks';

import './StatisticsWithImages.scss';

const StatisticsWithImages: FC<StatisticsWithImagesProps> = ({ module }) => {
  const Description = () => {
    if (!module.description) return null;

    return (
      <Row>
        <Col xl="12" md="8" sm="12" className="statistics-with-images__top col--no-gutters">
          <div
            className="title-text__rte rte"
            dangerouslySetInnerHTML={{ __html: module.description }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <section
      className={classNames(
        'statistics-with-images section-bg',
        commonColorHandler(module.backgroundColor[0])
      )}
    >
      <Container>
        <Row>
          <Col xl="12" md="12" sm="12" className="statistics-with-images__top col--no-gutters">
            <h2 className="statistics-with-images__title h2">{module.title}</h2>
            <LinkItem
              link={module.link}
              linkClass="statistics-with-images__link btn btn--outline"
              titleClass="statistics-with-images__link-text"
              hasIcon
              isLink
              showTitle
            />
          </Col>
        </Row>
        <Description />
        <Row>
          <Col xl="12" md="12" sm="12" className="statistics-with-images__col col--no-gutters">
            <StatisticsWithImagesBlocks module={module} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default StatisticsWithImages;
